import { useEffect, useState } from "react";
import { MobileContext } from "./MobileContext";

const MobileContextProvider = function ({ children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1050);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MobileContext.Provider value={{ isMobile }}>
      {children}
    </MobileContext.Provider>
  );
};

export default MobileContextProvider;
