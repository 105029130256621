import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category = "Homepage") => {
  const eventTracker = (action = "test action", label = "test label") => {
    const gclid = localStorage.getItem("gclid"); // Retrieve stored GCLID

    let eventName = action;
    if(gclid){
      eventName = action + ' Ads'
    }

    ReactGA.event(eventName, {
      category,
      action,
      label,
      nonInteraction: false,
      gclid: gclid || "No GCLID",
    });
  };

  return eventTracker;
};

export default useAnalyticsEventTracker;
