import Layout from "../../../components/Layout";
import ContactFormButton from "../contactFormButton";
import LocationState from "../locationState";
import StateHero from "../stateHero";
import { coloradoData } from "../data";
import MobileLocationState2 from "../mobileLocationState2";
import { useMobileContext } from "../../../components/MobileContext/MobileContextHook";

const Colorado = function () {
  const { isMobile } = useMobileContext();

  return (
    <Layout>
      <StateHero {...coloradoData} />
      {isMobile ? (
        <MobileLocationState2 {...coloradoData} />
      ) : (
        <LocationState {...coloradoData} />
      )}
      <ContactFormButton {...coloradoData} />
    </Layout>
  );
};

export default Colorado;
