import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./pages";
import ReactGA from "react-ga4";
// import Hiring from './pages/HiringPage';
// import { hiringData } from './pages/HiringPage/data';
import ScrollToTop from "./components/scrollToTop";
// import HiringConfirmation from './pages/HiringConfirmation';
import NotFound from "./components/NotFound";
import ContactPage from "./pages/ContactPage";
import Lead from "./pages/Lead";
import Refer from "./pages/Refer";
import Video from "./pages/Video";
import DealForm from "./pages/NewDeal";
import { navObj } from "./components/Navbar/data";
import OnboardingForm from "./pages/Onboarding";
import OnboardingSign from "./pages/Onboarding/OnboardingSign";
import Rejections from "./pages/Rejections";
import OnboardingVerify from "./pages/Onboarding/OnboardingVerify";
import SurveyForm from "./pages/Survey";
// import Brochure from "./pages/Commercial/brochure";
import Commercial from "./pages/Commercial";
import Locations from "./pages/ServiceAreas";
import Florida from "./pages/ServiceAreas/StatePages/florida";
import Massachusetts from "./pages/ServiceAreas/StatePages/massachusetts";
import RhodeIsland from "./pages/ServiceAreas/StatePages/rhodeIsland";
import Connecticut from "./pages/ServiceAreas/StatePages/connecticut";
import Colorado from "./pages/ServiceAreas/StatePages/colorado";
import SouthCarolina from "./pages/ServiceAreas/StatePages/southCarolina";
import NorthCarolina from "./pages/ServiceAreas/StatePages/northCarolina";
import FAQ from "./pages/FAQ";

const TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const captureGclid = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const gclid = urlParams.get("gclid");

  if (gclid) {
    // Store GCLID in localStorage (or use cookies)
    localStorage.setItem("gclid", gclid);
  }
};

function App() {
  useEffect(() => {
    captureGclid();
    ReactGA.send("pageview"); // Track initial page view
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path='/hiring' element={<Hiring {...hiringData}/>} /> */}
          {/* <Route path='/hiring-confirmation' element={<HiringConfirmation />} /> */}
          <Route path="/Happy-Holidays" element={<Video />} />
          <Route
            path="/contact-us"
            element={<ContactPage urlPath="standard" />}
          />
          <Route
            path="/contact-us/8qwdzyt9smeymr6ouwb5"
            element={<ContactPage urlPath="qrCode" />}
          />
          <Route path="/lead-form" element={<Lead />} />
          {/* <Route path='/commercial-brochure' element={<Brochure />} /> */}
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/deal-form/:id" element={<DealForm {...navObj} />} />
          <Route path="/survey-form/:id" element={<SurveyForm {...navObj} />} />
          {/* <Route path='/onboarding/Kq2VCFHr36PYbDvJ5V3L' element={<OnboardingForm {...navObj} urlPath="createSalesRep"/>} /> */}
          <Route
            path="/onboarding"
            element={
              <OnboardingForm
                {...navObj}
                urlPath="createSalesRepBeforeApproval"
              />
            }
          />
          <Route
            path="/onboarding-sign/:id"
            element={<OnboardingSign {...navObj} />}
          />
          <Route
            path="/onboarding-verify/:id"
            element={<OnboardingVerify {...navObj} />}
          />
          <Route path="/sunnova-rejections/:id" element={<Rejections />} />
          <Route path="/service-areas" element={<Locations />} />
          <Route path="/service-areas/florida" element={<Florida />} />
          <Route
            path="/service-areas/massachusetts"
            element={<Massachusetts />}
          />
          <Route path="/service-areas/rhodeisland" element={<RhodeIsland />} />
          <Route path="/service-areas/connecticut" element={<Connecticut />} />
          <Route path="/service-areas/colorado" element={<Colorado />} />
          <Route
            path="/service-areas/southcarolina"
            element={<SouthCarolina />}
          />
          <Route
            path="/service-areas/northcarolina"
            element={<NorthCarolina />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route
            path="/refer-email"
            element={<Refer source="lunexpower.com - Refer Email" />}
          />
          <Route
            path="/refer-mailer"
            element={<Refer source="lunexpower.com - Refer Mailer" />}
          />
          <Route path="*" status={404} element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
