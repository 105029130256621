import Layout from "../../components/Layout";
import LocationsHero from "./hero";
import ServiceAreaStates from "./stateLinkPage";
// import LocationState from "./locationState";
// import Cities from "./cities";
import ContactFormButton from "./contactFormButton";

const ServiceAreas = function () {
  return (
    <Layout>
      <LocationsHero />
      <ServiceAreaStates />
      {/* <LocationState />
      <Cities /> */}
      <ContactFormButton />
    </Layout>
  );
};

export default ServiceAreas;
