import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { 
    ContactContainer, 
    ContactForm, 
    ContactFormFields, 
    ContactFormInfoInput, 
    ContactFormInfoTitle, 
    ContactFormWrapper, 
    ContactInfo,
    ContactInfoLink,
    ContactInfoWrapper, 
    ContactWrapper, 
    FormColumn, 
    FormRow,
    FormMessageRow,
    Header, 
    HeaderTopLine, 
    HeaderWrapper, 
    ButtonContainer,
    ErrorMessage,
    RequiredErrorMessage,
    CaptchaContainer,
    ContactFormAutocomplete,
    CustomerAddressWrapper,
    CustomerInputDeleteIcon,
    CustomerInputLocateIcon,
    ContactFormAddressInput,
    ContactFormFileInput,
    ContactFormFileLabel,
    FileButtonWrapper,
    ClearFileButton
} from './ContactElements';
import './data.js';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconContext } from 'react-icons'
import { ButtonElement } from '../ContactForm/ContactFormElements';
import { Oval } from 'react-loading-icons';

const Contact = ({
    topLine,
    headline,
    description,
    addressFL,
    addressNE,
    phoneFL,
    phoneNE,
    email,
    urlPath
}) => {

    const onPlaceSelected = useRef(() => undefined);

    const gaEventTracker = useAnalyticsEventTracker('Contact Us');
    const captchaRef = useRef(null);

    const initialFormState = {
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contactAddress: '',
        monthlyBill: '',
        message: '',
        uploadedFile: null
    };

    const initialErrorFormState = {
        hasEmailAndIsValid: false,
        emailErrorMessage: '',
    }

    const [formState, setFormState] = useState(initialFormState);
    const [errorFormState, setErrorFormState] = useState(initialErrorFormState);
    const [submitting, setSubmitting] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [captcha, setCaptcha] = useState(true);
    const [showLocate, setShowLocate] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [office, setOffice] = useState('FL');
    const [state, setState] = useState('');


    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        await postSubmission();
        setSubmitting(false);
    };

    const postSubmission = async () => {

        try {
            const token = captchaRef.current.getValue();
            const url = process.env.REACT_APP_API_URL + 'captcha/' + token
            const result = await axios.get(url)
            
            if(result.data.verified){
                const formData = new FormData();
                formData.append("name", formState.contactName);
                formData.append("address", formState.contactAddress);
                formData.append("email", formState.contactEmail);
                formData.append("phone", formState.contactPhone);
                formData.append("electricBill", formState.monthlyBill);

                if(urlPath === 'qrCode'){
                    formData.append("message", "FROM QR CODE:" + formState.message); 
                }
                else{
                    formData.append("message", formState.message);
                }
                
                formData.append("file", formState.uploadedFile);

                const config = {
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + 'quote',
                    headers: {
                        'Content-Type': 'multipart/form-data', 
                    },
                    data: formData
                };
                
                const result2 = await axios(config);
                console.log(result2);
                setFormState(initialFormState);
                setSelectedFileName('');
                document.getElementById('uploadedFile').value = '';
                setShowLocate(false);
                setDisabledButton(true);
                setErrorFormState(initialErrorFormState);
                toast.success('Quote requested, someone will reach out shortly!', { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });
            }
            captchaRef.current.reset();
            setCaptcha(true);

        } catch (error) {
            console.log(error);
            toast.error('There was an error requesting quote', { theme: "colored", position: toast.POSITION.BOTTOM_RIGHT });
        }
    };

    const updateFormControl = (event) => {
        const { id, type, value, files } = event.target;
        const formKey = id;
        const updatedFormState = { ...formState };
        const updatedErrorFormState = {...errorFormState};

        if (type === 'file') {
            const fileSizeMB = files[0].size / (1024 * 1024);
            if (fileSizeMB > 10) {
                alert("File size must be under 10MB.");
                event.target.value = "";
            } else {
                console.log("formKey ", formKey);
                console.log("filaname ", files[0].name);
                setSelectedFileName(files[0].name);
                updatedFormState[formKey] = files[0];
                setFormState(updatedFormState);
                return;
            }
        }
        

        // Validate Fields
        switch(id) {
            case 'contactEmail':
                if(value.length === 0){
                    updatedErrorFormState.hasEmailAndIsValid = false;
                    updatedErrorFormState.emailErrorMessage = '';
                    updatedFormState[formKey] = value;
                    break;
                }
                if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    updatedErrorFormState.hasEmailAndIsValid = false;
                    updatedErrorFormState.emailErrorMessage = 'Email is invalid';
                    updatedFormState[formKey] = value;
                    break;
                }
                else{
                    updatedErrorFormState.hasEmailAndIsValid = true;
                    updatedErrorFormState.emailErrorMessage = '';
                    updatedFormState[formKey] = value;
                }
                break;
            case 'contactPhone':
                const formattedPhoneNumber = formatPhoneNumber(value);
                updatedFormState[formKey] = formattedPhoneNumber;
                break;
            default:
                updatedFormState[formKey] = value;
                break;
                
        }

        function formatPhoneNumber(value) {
            // if input value is falsy eg if the user deletes the input, then just return
            if (!value) return value;
          
            // clean the input for any non-digit values.
            const phoneNumber = value.replace(/[^\d]/g, '');
          
            // phoneNumberLength is used to know when to apply our formatting for the phone number
            const phoneNumberLength = phoneNumber.length;
          
            // we need to return the value with no formatting if its less then four digits
            // this is to avoid weird behavior that occurs if you  format the area code to early
          
            if (phoneNumberLength < 4) return phoneNumber;
          
            // if phoneNumberLength is greater than 4 and less the 7 we start to return
            // the formatted number
            if (phoneNumberLength < 7) {
              return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
          
            // finally, if the phoneNumberLength is greater then seven, we add the last
            // bit of formatting and return it.
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
              3,
              6
            )}-${phoneNumber.slice(6, 10)}`;
          }

        

        if(updatedErrorFormState.hasEmailAndIsValid)
        {   
            setDisabledButton(false)
        }
        else {
            setDisabledButton(true);
        }
        setErrorFormState(updatedErrorFormState);
        setFormState(updatedFormState);
        
    };

    const updateFormControlAddy = useCallback((event) => {
        const updatedFormState = { ...formState };
        updatedFormState['contactAddress'] = event.formatted_address;
        setFormState(updatedFormState);
      }, [formState,setFormState]);
    
    const handleLocate = async () => {
        setShowLocate(true);
        try{
            navigator.geolocation.getCurrentPosition(async function(position) {

                const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + process.env.REACT_APP_GOOGLE_MAPS_API;

                const result = await axios.get(url);
                console.log("result", result);

                const addressComponents = result.data.results[0].address_components;
                let state = '';

                for (let component of addressComponents) {
                    if (component.types.includes('administrative_area_level_1')) {
                        state = component.short_name;
                        break;
                    }
                }

                console.log("state ", state);

                setState(state);
                setShowLocate(true);
                determineAddress(state);

                updateFormControlAddy(result.data.results[0]); 

            });
        }
        catch(error){
            // console.log("Error encounterd while getting location ", error);
        }
    }

      const determineAddress = (state) => {
        const newEnglandStates = ['CT', 'ME', 'MA', 'NH', 'RI', 'VT', 'NJ'];
        if (newEnglandStates.includes(state)) {
          setOffice('NE');
        } else {
          setOffice('FL');
        }
      };
    
      const handleDelete = () => {
        setShowLocate(false);
        const updatedFormState = { ...formState };
        updatedFormState['contactAddress'] = '';
        setFormState(updatedFormState);
      }

      function handleCaptcha(value) {
        setCaptcha(false);
        // console.log('Captcha value:', value);
    }
    
    const clearFile = () => {
        setSelectedFileName(''); 
        document.getElementById('uploadedFile').value = '';
    };

    useEffect(() => {
        onPlaceSelected.current = (place) => {
            updateFormControlAddy(place[0]);
        };
    }, [updateFormControlAddy]);
    

  return (
        
        <ContactContainer id='requestquote'>
            <ToastContainer />
            <HeaderWrapper>
                <HeaderTopLine>{topLine}</HeaderTopLine>
                <Header>{headline}</Header>
            </HeaderWrapper>
            <ContactWrapper>
                <ContactInfoWrapper>
                    <ContactInfo>{description}</ContactInfo>
                    {office === 'NE' ? (<ContactInfo>{addressNE}</ContactInfo>) : <ContactInfo>{addressFL}</ContactInfo>}
                    {office === 'NE' ? (<ContactInfoLink href='tel:+1-508-373-2455' onClick={()=>gaEventTracker('call')}>{phoneNE}</ContactInfoLink>): <ContactInfoLink href='tel:+1-813-540-8807' onClick={()=>gaEventTracker('call')}>{phoneFL}</ContactInfoLink>}
                    <ContactInfo></ContactInfo>
                    <ContactInfoLink href={`mailto:${email}`} onClick={()=>gaEventTracker('email')}>{email}</ContactInfoLink>
                </ContactInfoWrapper>
                <ContactFormWrapper>
                    <ContactForm onSubmit={submitForm}>
                        <ContactFormFields>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactName'>Name</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='contactName' value={formState.contactName} onChange={updateFormControl} ></ContactFormInfoInput>                            
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactEmail'>Email</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='contactEmail' value={formState.contactEmail} onChange={updateFormControl} ></ContactFormInfoInput>
                                    {errorFormState.emailErrorMessage && <ErrorMessage>{errorFormState.emailErrorMessage}</ErrorMessage>}
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactPhone'>Phone number</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='contactPhone' value={formState.contactPhone} onChange={updateFormControl} ></ContactFormInfoInput>
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='monthlyBill'>Average Electric Bill /mo</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='monthlyBill' value={formState.monthlyBill} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn>
                                
                            </FormRow>
                            <FormRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='contactAddress'>Address</ContactFormInfoTitle>
                                    <CustomerAddressWrapper>
                                        <IconContext.Provider
                                            value={{ size: '35px' }}
                                        >
                                            {!showLocate &&
                                                <>
                                                    <ContactFormAutocomplete 
                                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                                                        onPlaceSelected={(...args) => onPlaceSelected.current(args)}
                                                        options={{
                                                            types: ["address"],
                                                            componentRestrictions: { country: "us" },
                                                        }}
                                                        
                                                        id='contactAddress'
                                                        defaultValue={formState.contactAddress} 
                                                    />
                                                    <CustomerInputLocateIcon 
                                                        onClick={handleLocate}
                                                    />
                                                </>
                                            }
                                            {showLocate &&
                                                <>
                                                    <ContactFormAddressInput type='text' id='contactAddress' value={formState.contactAddress} onChange={(updateFormControlAddy)} />
                                                    <CustomerInputDeleteIcon 
                                                        onClick={handleDelete}
                                                    />
                                                </>
                                            }

                                        </IconContext.Provider>
                                    </CustomerAddressWrapper>
                                     
                                </FormColumn>
                                <FormColumn>
                                    <ContactFormInfoTitle>Electric Bill</ContactFormInfoTitle>
                                    <ContactFormFileInput 
                                        type='file' 
                                        id='uploadedFile' 
                                        onChange={updateFormControl} 
                                        name={"file"}
                                    />
                                    <FileButtonWrapper>
                                        <ContactFormFileLabel htmlFor='uploadedFile'>
                                            {selectedFileName ? selectedFileName : 'Choose File'}
                                        </ContactFormFileLabel>
                                        {selectedFileName && 
                                            <ClearFileButton onClick={clearFile}>
                                                X
                                            </ClearFileButton>
                                        }
                                    </FileButtonWrapper>
                                </FormColumn>


                                {/* <FormColumn>
                                    <ContactFormInfoTitle htmlFor='uploadedFile'>Electric Bill</ContactFormInfoTitle>
                                    <ContactFormFileInput type='file' id='uploadedFile' onChange={updateFormControl} name={"file"}/>
                                    <FileButtonWrapper>
                                        <ContactFormFileLabel htmlFor='uploadedFile'>
                                            {selectedFileName ? selectedFileName : 'Choose File'}
                                            {selectedFileName && <span onClick={clearFile} style={{ cursor: 'pointer', marginLeft: '10px' }}>X</span>}
                                        </ContactFormFileLabel> 
                                    </FileButtonWrapper>
                                </FormColumn> */}
                            </FormRow>
                            <FormMessageRow>
                                <FormColumn>
                                    <ContactFormInfoTitle htmlFor='message'>Message</ContactFormInfoTitle>
                                    <ContactFormInfoInput type='text' id='message' value={formState.message} onChange={updateFormControl} ></ContactFormInfoInput>  
                                </FormColumn>
                            </FormMessageRow>
                        </ContactFormFields>
                        <CaptchaContainer>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={handleCaptcha}
                                ref={captchaRef}
                            />
                        </CaptchaContainer>
                        <ButtonContainer>
                        {!submitting &&
                            <ButtonElement id="quote-button-submit" type="submit" disabled={disabledButton || submitting|| captcha} onClick={() => gaEventTracker("Submit Form", "Clicked Request a Quote")}>Request a Quote</ButtonElement>

                        }
                        {submitting &&
                            <ButtonElement>
                                <Oval height='1.5rem'/>
                            </ButtonElement>
                        }
                        </ButtonContainer>
                        {disabledButton && (
                            <RequiredErrorMessage>Email Required</RequiredErrorMessage>
                        )}
                        
                    </ContactForm>
                </ContactFormWrapper>
            </ContactWrapper>

        </ContactContainer>
  )
}

export default Contact