import { useState } from "react";
import styled from "styled-components";

export const SectionWrapper = styled.div`
  background-color: white;
  background-color: #fffdfa;
  background-color: #fffcf8;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
`;

export const SectionTitle = styled.h2`
  font-size: 3.6rem;
  font-weight: 500;
  color: #565b60;
  color: #ffa500;
  margin-bottom: 2rem;

  @media (max-width: 46.875em) {
    //750px
    font-size: 2.7rem;
  }

  @media (max-width: 28.125em) {
    //450px
    font-size: 2.4rem;
  }
`;

export const QuestionSymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const Question = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: #565b60;
  /* padding-right: 1rem; */

  @media (max-width: 46.875em) {
    //750px
    font-size: 1.2rem;
  }

  @media (max-width: 28.125em) {
    //450px
    font-size: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Symbol = styled.span`
  font-size: 2.4rem;
  margin-left: auto;
  font-weight: 600;
  color: #565b60;
  cursor: pointer;
  user-select: none;
  color: #ffa500;

  @media (max-width: 46.875em) {
    //750px
    font-size: 1.6rem;
  }
`;

export const FullQAWrapper = styled.div`
  transition: all 1s;
  display: flex;
  flex-direction: column;
  /* gap: 1.4rem; */
  /* padding-left: 1rem; */
  padding: 1.4rem 0 1.4rem 1rem;
  border-bottom: 2px solid #ffa500;

  @media (max-width: 46.875em) {
    //750px
    padding: 1.4rem 0 1.4rem 0.5rem;
  }

  @media (max-width: 28.125em) {
    //450px
    padding: 1.4rem 0;
  }

  &:first-of-type {
    padding: 0 0 1.4rem 1rem;

    @media (max-width: 46.875em) {
      //750px
      padding: 0 0 1.4rem 0.5rem;
    }

    @media (max-width: 28.125em) {
      //450px
      padding: 0 0 1.4rem 0;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Answer = styled.p`
  font-size: 1.2rem;
  color: #565b60;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out, margin-top 0.2s ease-in-out;
  cursor: pointer;

  @media (max-width: 46.875em) {
    //750px
    font-size: 0.8rem;
  }

  &.open {
    max-height: 40rem;
    margin-top: 1rem;
    @media (max-width: 46.875em) {
      //750px
      margin-top: 0.8rem;
    }
  }
`;

const FAQSection = function ({ title, data }) {
  const [openIndex, setOpenIndex] = useState(0);

  const handleToggle = function (index) {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <SectionWrapper>
      <SectionTitle>{title}</SectionTitle>
      {data.map((el, i) => (
        <FullQAWrapper key={i}>
          <QuestionSymbolWrapper>
            <Question onClick={() => handleToggle(i)}>{el.question}</Question>
            <Symbol onClick={() => handleToggle(i)}>
              {openIndex === i ? "-" : "+"}
            </Symbol>
          </QuestionSymbolWrapper>
          <Answer
            className={openIndex === i ? "open" : ""}
            onClick={() => setOpenIndex(null)}
          >
            {el.answer}
          </Answer>
        </FullQAWrapper>
      ))}
    </SectionWrapper>
  );
};

export default FAQSection;
