import Layout from "../../../components/Layout";
import Cities from "../cities";
import ContactFormButton from "../contactFormButton";
import LocationState from "../locationState";
import StateHero from "../stateHero";
import { massachusettsData } from "../data";
import { useMobileContext } from "../../../components/MobileContext/MobileContextHook";
import MobileLocationState2 from "../mobileLocationState2";

const Massachusetts = function () {
  const { isMobile } = useMobileContext();

  return (
    <Layout>
      <StateHero {...massachusettsData} />
      {isMobile ? (
        <MobileLocationState2 {...massachusettsData} />
      ) : (
        <LocationState {...massachusettsData} />
      )}

      <Cities {...massachusettsData} />
      <ContactFormButton {...massachusettsData} />
    </Layout>
  );
};

export default Massachusetts;
