import floridaHero from "../../images/ServiceAreas/BigHouseLunexTruck.webp";
import massachusettsHero from "../../images/ServiceAreas/PointyRoofPanels.webp";
import rhodeIslandHero from "../../images/ServiceAreas/RhodeIslandHero.webp";
import connecticutHero from "../../images/ServiceAreas/ConnecticutHero.webp";
import coloradoHero from "../../images/ServiceAreas/ColoradoHero.webp";
import northCarolinaHero from "../../images/ServiceAreas/NorthCarolinaHero.webp";
import southCarolinaHero from "../../images/ServiceAreas/SouthCarolinaHero.webp";
import floridaIMG from "../../images/ServiceAreas/FloridaLocationIMG.webp";
import massachusettsIMG from "../../images/ServiceAreas/MassachusettsLocationIMG.webp";
import rhodeIslandIMG from "../../images/ServiceAreas/RhodeIslandLocationIMG.webp";
import connecticutIMG from "../../images/ServiceAreas/ConnecticutLocationIMG.webp";
import coloradoIMG from "../../images/ServiceAreas/ColoradoLocationIMG.webp";
import northCarolinaIMG from "../../images/ServiceAreas/NorthCarolinaLocationIMG.webp";
import southCarolinaIMG from "../../images/ServiceAreas/SouthCarolinaLocationIMG.webp";
import tampaSkyline from "../../images/ServiceAreas/TampaSkyline.webp";
import orlandoSkyline from "../../images/ServiceAreas/OrlandoSkyline.webp";
import miamiSkyline from "../../images/ServiceAreas/MiamiSkyline.webp";
import worcesterSkyline from "../../images/ServiceAreas/WorcesterSkyline.webp";
import bostonSkyline from "../../images/ServiceAreas/BostonSkyline.webp";
import springfieldSkyline from "../../images/ServiceAreas/SpringfieldSkyline.webp";
import providenceSkyline from "../../images/ServiceAreas/ProvidenceSkyline.webp";
import cranstonSkyline from "../../images/ServiceAreas/CranstonSkyline.webp";
import warwickSkyline from "../../images/ServiceAreas/WarwickSkyline.webp";
import hartfordSkyline from "../../images/ServiceAreas/HartfordSkyline.webp";
import bridgeportSkyline from "../../images/ServiceAreas/BridgeportSkyline.webp";
import newHavenSkyline from "../../images/ServiceAreas/NewHavenSkyline.webp";
import denverSkyline from "../../images/ServiceAreas/DenverSkyline.webp";
import coloradoSpringsSkyline from "../../images/ServiceAreas/ColoradoSpringsSkyline.webp";
import auroraSkyline from "../../images/ServiceAreas/AuroraSkyline.webp";
import charlotteSkyline from "../../images/ServiceAreas/CharlotteSkyline.webp";
import raleighSkyline from "../../images/ServiceAreas/RaleighSkyline.webp";
import greensboroSkyline from "../../images/ServiceAreas/GreensboroSkyline.webp";
import charlestonSkyline from "../../images/ServiceAreas/CharlestonSkyline.webp";
import columbiaSkyline from "../../images/ServiceAreas/ColumbiaSkyline.webp";
import greenvilleSkyline from "../../images/ServiceAreas/GreenvilleSkyline.webp";

import { FL, MA, RI, CT, CO, NC, SC } from "@state-icons/react";

export const floridaData = {
  stateName: "Florida",
  stateTitle: "FLORIDA",
  stateTitleSubHeader: "The Sunshine State and Our Home Base",
  heroImage: floridaHero,
  heroBackgroundPosition: "top",
  heroIcon: <FL size={200} color="#ffa500" />,
  heroIcon2: <FL size={150} color="#ffa500" />,
  image1: floridaIMG,
  image1Alt: "Solar House with Pool",
  image2: tampaSkyline,
  image2Alt: "Tampa Skyline",
  image3: orlandoSkyline,
  image3Alt: "Orlando Skyline",
  image4: miamiSkyline,
  image4Alt: "Miami Skyline",
  cityTitle1: "TAMPA - Where Lunex Power Got Its Start",
  cityTitle2: "ORLANDO - Magic Beyond the Theme Parks",
  cityTitle3: "MIAMI - Sunshine, Style, and Sustainability",
  paragraph1:
    "Lunex Power is proud to call Florida home, with our headquarters nestled in the vibrant city of Tampa. Just like Florida's boundless sunshine, our commitment to clean energy is unwavering. We're on a mission to help homeowners across the state harness the power of the sun, cutting energy costs while embracing a more sustainable future.",
  paragraph2:
    "Tampa isn't just where our headquarters is—it's where our journey began. From the historic charm of Ybor City to the cutting-edge innovation in the downtown district, Tampa embodies both tradition and progress—just like Lunex Power. We're dedicated to helping homeowners and businesses alike make the switch to solar in a city that thrives on forward-thinking solutions.",
  paragraph3:
    "Orlando might be best known for its world-famous attractions, but we believe the real magic happens when homeowners take control of their energy future. Our team is dedicated to bringing high-quality solar solutions to this bustling city, ensuring that even in the land of fantasy, energy savings are a very real possibility.",
  paragraph4:
    "Miami sets the standard for vibrant living, and Lunex Power is here to ensure that standard includes sustainable energy. From the sleek high-rises of Brickell to the colorful streets of Little Havana, we're helping homeowners and businesses alike tap into the city's endless sunshine. After all, Miami is all about staying ahead of the curve—why should energy be any different?",
  carousel: true,
  carouselScrollDuration: 300,
  cities: [
    "Alachua",
    "Aloma",
    "Altamonte Springs",
    "Apollo Beach",
    "Apopka",
    "Arcadia",
    "Archer",
    "Auburndale",
    "Ave Maria",
    "Avon Park",
    "Babson Park",
    "Bartow",
    "Belleview",
    "Beverly Hills",
    "Boca Raton",
    "Bokeelia",
    "Bonita Springs",
    "Bradenton",
    "Brandon",
    "Brooksville",
    "Bunnell",
    "Cantonment",
    "Cape Coral",
    "Casselberry",
    "Center Hill",
    "Citra",
    "Citrus Springs",
    "Clearwater",
    "Clermont",
    "Clewiston",
    "Cocoa",
    "Coral Springs",
    "Crescent City",
    "Crestview",
    "Cross City",
    "Crystal River",
    "Dade City",
    "Dania Beach",
    "Davenport",
    "Daytona Beach",
    "DeBary",
    "DeLand",
    "Delray Beach",
    "Deltona",
    "Destin",
    "Doral",
    "Dover",
    "Duette",
    "Dundee",
    "Dunedin",
    "Dunnellon",
    "Eagle Lake",
    "East Palatka",
    "Eastpoint",
    "Edgewater",
    "Ellenton",
    "Englewood",
    "Estero",
    "Fanning Springs",
    "Floral City",
    "Fort Denaud",
    "Fort Lauderdale",
    "Fort McCoy",
    "Fort Myers",
    "Fort Walton Beach",
    "Frostproof",
    "Gainesville",
    "Gibsonton",
    "Glen Saint Mary",
    "Greenacres",
    "Groveland",
    "Gulf Breeze",
    "Gulfport",
    "Haines City",
    "Hastings",
    "Hawthorne",
    "Hernando",
    "Hialeah",
    "High Springs",
    "Hobe Sound",
    "Holiday",
    "Hollywood",
    "Homosassa",
    "Howey-in-the-Hills",
    "Hudson",
    "Immokalee",
    "Indialantic",
    "Indian Lake Estates",
    "Indian Rocks Beach",
    "Interlachen",
    "Inverness",
    "Jacksonville",
    "Jan Phyl Village",
    "Jasper",
    "Jupiter",
    "Kathleen",
    "Key Largo",
    "Kissimmee",
    "LaBelle",
    "Lady Lake",
    "Lake Alfred",
    "Lake Butler",
    "Lake City",
    "Lake Mary",
    "Lake Placid",
    "Lake Wales",
    "Lake Worth Beach",
    "Lakeland",
    "Lakewood Ranch",
    "Land O' Lakes",
    "Largo",
    "Lecanto",
    "Lee",
    "Leesburg",
    "Lehigh Acres",
    "Lithia",
    "Live Oak",
    "Longwood",
    "Lorida",
    "Loxahatchee",
    "Lutz",
    "Macclenny",
    "Madeira Beach",
    "McAlpin",
    "Melbourne",
    "Melrose",
    "Merritt Island",
    "Miami",
    "Micanopy",
    "Middleburg",
    "Milton",
    "Mims",
    "Minneola",
    "Montverde",
    "Morriston",
    "Mount Dora",
    "Mount Plymouth",
    "Mulberry",
    "Myakka City",
    "Naples",
    "Navarre",
    "New Port Richey",
    "Niceville",
    "Nokomis",
    "North Fort Myers",
    "North Palm Beach",
    "North Port",
    "Oakland",
    "Ocala",
    "Ocklawaha",
    "Ocoee",
    "Odessa",
    "Okahumpka",
    "Okeechobee",
    "Oldsmar",
    "Orange City",
    "Orange Park",
    "Orlando",
    "Ormond Beach",
    "Oviedo",
    "Pace",
    "Palatka",
    "Palm Bay",
    "Palm Beach Gardens",
    "Palm Coast",
    "Palm Harbor",
    "Palm River-Clair Mel",
    "Palmetto",
    "Palmetto Bay",
    "Panama City Beach",
    "Parrish",
    "Pembroke Pines",
    "Pensacola",
    "Perry",
    "Pinellas Park",
    "Plant City",
    "Plantation",
    "Polk City",
    "Pomona Park",
    "Port Charlotte",
    "Port Orange",
    "Port Richey",
    "Port St. Joe",
    "Port St. Lucie",
    "Punta Gorda",
    "Raiford",
    "Redington Beach",
    "Redington Shores",
    "Riverview",
    "Riviera Beach",
    "Rotonda West",
    "Royal Palm Beach",
    "Ruskin",
    "Safety Harbor",
    "San Antonio",
    "Sarasota",
    "Satellite Beach",
    "Satsuma",
    "Sebring",
    "Seffner",
    "Seminole",
    "South Daytona",
    "Spring Hill",
    "St. Augustine",
    "St. Cloud",
    "St. Pete Beach",
    "St. Petersburg",
    "Starke",
    "Stuart",
    "Summerfield",
    "Sun City Center",
    "Sunrise",
    "Tallahassee",
    "Tamarac",
    "Tampa",
    "Tarpon Springs",
    "Temple Terrace",
    "The Villages",
    "Thonotosassa",
    "Titusville",
    "Trenton",
    "Trinity",
    "Valrico",
    "Venice",
    "Vero Beach",
    "Wauchula",
    "Weirsdale",
    "Wellborn",
    "Wellington",
    "Wesley Chapel",
    "West Melbourne",
    "West Palm Beach",
    "Wewahitchka",
    "White Springs",
    "Wildwood",
    "Williston",
    "Wilton Manors",
    "Wimauma",
    "Windermere",
    "Winter Garden",
    "Winter Haven",
    "Winter Park",
    "Winter Springs",
    "Zephyrhills",
  ],
};

export const massachusettsData = {
  stateName: "Massachusetts",
  stateTitle: "MASSACHUSETTS",
  stateTitleSubHeader: "Innovation Meets Tradition",
  heroImage: massachusettsHero,
  heroBackgroundPosition: "center 40%",
  heroIcon: <MA size={200} color="#ffa500" />,
  heroIcon2: <MA size={150} color="#ffa500" />,
  image1: massachusettsIMG,
  image1Alt: "Solar House with Pointy Roof",
  image2: worcesterSkyline,
  image2Alt: "Worcester Skyline",
  image3: bostonSkyline,
  image3Alt: "Boston Skyline",
  image4: springfieldSkyline,
  image4Alt: "Springfield Skyline",
  cityTitle1: "WORCESTER - A Personal Mission",
  cityTitle2: "BOSTON - Leading the Charge",
  cityTitle3: "SPRINGFIELD - Solar is a Slam Dunk",
  paragraph1:
    "Massachusetts has long been a hub of innovation, and solar energy is no exception. With a commitment to renewable energy policies and forward-thinking homeowners, the Bay State is a perfect match for Lunex Power. We're proud to bring cutting-edge solar technology to a state that's always looking toward the future.",
  paragraph2:
    "Our roots run deep in Worcester—after all, it's where our founder grew up and where we proudly maintain an office today. From the iconic Worcester Art Museum to the bustling Canal District, this city thrives on grit and determination. We share that same mindset, working tirelessly to bring cost-saving, high-efficiency solar solutions to the community we call home.",
  paragraph3:
    "Boston is no stranger to revolution, and we're here to spark another one — this time in solar energy. From Beacon Hill to Back Bay, homeowners and businesses are turning to Lunex Power for a brighter, cleaner energy future. With a city that's always ahead of the curve, it's only natural that solar power is becoming the next big thing in Beantown.",
  paragraph4:
    "Springfield put itself on the map by inventing basketball—now it's embracing the future with solar. Lunex Power is helping homeowners cut energy costs and bring sustainability to the Pioneer Valley. Whether near the Basketball Hall of Fame or the Connecticut River, solar power is a winning play.",
  carousel: true,
  carouselScrollDuration: 162,
  cities: [
    "Abington",
    "Acton",
    "Adams",
    "Agawam",
    "Ashburnham",
    "Ashland",
    "Athol",
    "Attleboro",
    "Auburn",
    "Ayer",
    "Barnstable",
    "Belchertown",
    "Bellingham",
    "Beverly",
    "Bolton",
    "Boston",
    "Bourne",
    "Boylston",
    "Bridgewater",
    "Brockton",
    "Brookline",
    "Burlington",
    "Carlisle",
    "Carver",
    "Charlton",
    "Chatham",
    "Chelmsford",
    "Cheshire",
    "Concord",
    "Dartmouth",
    "Dracut",
    "Duxbury",
    "East Longmeadow",
    "Easthampton",
    "Falmouth",
    "Fitchburg",
    "Framingham",
    "Gardner",
    "Gill",
    "Gloucester",
    "Grafton",
    "Granby",
    "Great Barrington",
    "Hamilton",
    "Hampden",
    "Harvard",
    "Harwich",
    "Haverhill",
    "Holbrook",
    "Holland",
    "Hopkinton",
    "Kingston",
    "Lawrence",
    "Leominster",
    "Lexington",
    "Lincoln",
    "Longmeadow",
    "Lowell",
    "Ludlow",
    "Lunenburg",
    "Lynn",
    "Malden",
    "Marlborough",
    "Marshfield",
    "Maynard",
    "Medford",
    "Melrose",
    "Mendon",
    "Methuen",
    "Milford",
    "Millbury",
    "Montgomery",
    "Natick",
    "Needham",
    "Newbury",
    "None",
    "Norfolk",
    "North Adams",
    "North Andover",
    "North Brookfield",
    "North Reading",
    "Northampton",
    "Northbridge",
    "Orange",
    "Otis",
    "Oxford",
    "Pembroke",
    "Petersham",
    "Pittsfield",
    "Plainville",
    "Plymouth",
    "Quincy",
    "Randolph",
    "Revere",
    "Rochester",
    "Rutland",
    "Salem",
    "Shrewsbury",
    "Site Address City",
    "Somerset",
    "Somerville",
    "Southbridge",
    "Southwick",
    "Spencer",
    "Springfield",
    "Stockbridge",
    "Stoneham",
    "Sudbury",
    "Sutton",
    "Taunton",
    "Tewksbury",
    "Townsend",
    "Tyngsborough",
    "Upton",
    "Uxbridge",
    "Walpole",
    "Waltham",
    "Webster",
    "Wellfleet",
    "West Bridgewater",
    "West Brookfield",
    "West Springfield",
    "Westford",
    "Westwood",
    "Weymouth",
    "Whitman",
    "Wilbraham",
    "Winchendon",
    "Winchester",
    "Woburn",
    "Worcester",
    "Wrentham",
  ],
};

export const rhodeIslandData = {
  stateName: "Rhode Island",
  stateTitle: "RHODE ISLAND",
  stateTitleSubHeader: "Small State, Big Solar Potential",
  heroImage: rhodeIslandHero,
  heroIcon: <RI size={200} color="#ffa500" />,
  heroIcon2: <RI size={150} color="#ffa500" />,
  image1: rhodeIslandIMG,
  image1Alt: "Solar House with Beautifully Lined Panels",
  image2: providenceSkyline,
  image2Alt: "Providence Skyline",
  image3: cranstonSkyline,
  image3Alt: "Cranston Skyline",
  image4: warwickSkyline,
  image4Alt: "Warwick Skyline",
  cityTitle1: "PROVIDENCE - Where Tradition Meets Progress",
  cityTitle2: "CRANSTON - Solar with a Local Flavor",
  cityTitle3: "WARWICK - Sun-Powered by the Shore",
  paragraph1:
    "Rhode Island may be the smallest state, but when it comes to embracing clean energy, it's making a massive impact. Lunex Power is dedicated to bringing reliable and affordable solar solutions to Ocean State residents, ensuring that this coastal gem continues to shine sustainably.",
  paragraph2:
    "Providence seamlessly blends historic charm with modern energy solutions, making it the perfect place for Lunex Power to thrive. Just as WaterFire lights up the city, we're illuminating the benefits of solar energy for homeowners who want to lower their energy bills while reducing their carbon footprint.",
  paragraph3:
    "Cranston knows a thing or two about homegrown traditions, and we're here to add solar energy to the list. Just like a refreshing glass of coffeemilk, our solar solutions are a perfect blend of innovation and local charm. We're making it easier than ever for Cranston homeowners to make the switch to solar, ensuring a brighter future for all.",
  paragraph4:
    "Warwick's coastal beauty makes it a standout, and Lunex Power is here to keep it shining. With plenty of sun and eco-conscious homeowners, the city is a prime spot for solar. We're making the switch to clean energy easier than ever for Warwick residents.",
  carousel: true,
  carouselScrollDuration: 28,
  cities: [
    "Barrington",
    "Bristol",
    "Central Falls",
    "Coventry",
    "Cranston",
    "East Greenwich",
    "East Providence",
    "Foster",
    "Glocester",
    "Jamestown",
    "Johnston",
    "Narragansett",
    "North Providence",
    "Pawtucket",
    "Portsmouth",
    "Providence",
    "Richmond",
    "Smithfield",
    "South Kingstown",
    "Warren",
    "Warwick",
    "Westerly",
    "Woonsocket",
  ],
};

export const connecticutData = {
  stateName: "Connecticut",
  stateTitle: "CONNECTICUT",
  stateTitleSubHeader: "Powering the Constitution State",
  heroImage: connecticutHero,
  heroIcon: <CT size={200} color="#ffa500" />,
  heroIcon2: <CT size={150} color="#ffa500" />,
  image1: connecticutIMG,
  image1Alt: "Solar House from Front",
  image2: hartfordSkyline,
  image2Alt: "Hartford Skyline",
  image3: bridgeportSkyline,
  image3Alt: "Bridgeport Skyline",
  image4: newHavenSkyline,
  image4Alt: "New Haven Skyline",
  cityTitle1: "HARTFORD - A Capital Idea for Solar",
  cityTitle2: "BRIDGEPORT - Rewriting the Energy Playbook",
  cityTitle3: "NEW HAVEN - Smart Energy for a Smart City",
  paragraph1:
    "Connecticut has a long history of leadership and innovation, and Lunex Power is here to keep that legacy alive—this time in solar energy. With forward-thinking homeowners and businesses embracing renewables, we're helping the Nutmeg State take full advantage of its solar potential.",
  paragraph2:
    "As the capital city, Hartford sets the tone for the entire state, and when it comes to clean energy, it's leading by example. Lunex Power is committed to making solar a staple for Hartford homeowners, proving that even in a city rich with history, the future is brighter with renewable energy.",
  paragraph3:
    "Bridgeport has long been a city of transformation, and solar energy is the next big shift. With a focus on affordability and sustainability, we're helping homeowners and businesses break free from high energy costs. A city built on industry deserves an energy source that works just as hard.",
  paragraph4:
    "New Haven thrives on knowledge and progress, and solar energy is no exception. Lunex Power is helping homeowners tap into cutting-edge solar solutions, reducing costs while embracing sustainability. In a city that values innovation, solar is the smartest choice.",
  carousel: true,
  carouselScrollDuration: 87,
  cities: [
    "Andover",
    "Ansonia",
    "Bethlehem",
    "Bloomfield",
    "Bridgeport",
    "Bristol",
    "Brookfield",
    "Burlington",
    "Canterbury",
    "Canton",
    "Colchester",
    "Columbia",
    "Coventry",
    "Danbury",
    "Derby",
    "East Granby",
    "East Hampton",
    "East Hartford",
    "East Lyme",
    "East Windsor",
    "Essex",
    "Fairfield",
    "Glastonbury",
    "Greenwich",
    "Groton",
    "Hamden",
    "Hartford",
    "Killingly",
    "Lebanon",
    "Ledyard",
    "Manchester",
    "Marlborough",
    "Meriden",
    "Middletown",
    "Milford",
    "Montville",
    "Naugatuck",
    "New Britain",
    "New Hartford",
    "Newington",
    "Newtown",
    "Northford",
    "Norwalk",
    "Orange",
    "Plainfield",
    "Plainville",
    "Plymouth",
    "Putnam",
    "Ridgefield",
    "Rocky Hill",
    "Shelton",
    "Somers",
    "South Windsor",
    "Southington",
    "Sprague",
    "Stamford",
    "Thompson",
    "Tolland",
    "Trumbull",
    "Vernon",
    "Waterbury",
    "Waterford",
    "Watertown",
    "West Haven",
    "Wethersfield",
    "Willington",
    "Windham",
    "Windsor",
    "Windsor Locks",
    "Wolcott",
    "Woodbury",
  ],
};

export const coloradoData = {
  stateName: "Colorado",
  stateTitle: "COLORADO",
  stateTitleSubHeader: "Harnessing the Power of the Rockies",
  heroImage: coloradoHero,
  heroIcon: <CO size={200} color="#ffa500" />,
  heroIcon2: <CO size={150} color="#ffa500" />,
  image1: coloradoIMG,
  image1Alt: "Solar House with Elegant Panel Arrangement",
  image2: denverSkyline,
  image2Alt: "Denver Skyline",
  image3: coloradoSpringsSkyline,
  image3Alt: "Colorado Springs Skyline",
  image4: auroraSkyline,
  image4Alt: "Aurora Skyline",
  cityTitle1: "DENVER - A Mile High and Energy Efficient",
  cityTitle2: "COLORADO SPRINGS - Clean Energy at the Base of the Rockies",
  cityTitle3: "AURORA - Sunshine and Sustainability",
  paragraph1:
    "Colorado's stunning landscapes and commitment to environmental conservation make it the perfect place for Lunex Power. With abundant sunshine and eco-conscious homeowners, we're making it easier than ever for Coloradans to embrace solar energy.",
  paragraph2:
    "Denver is all about balance—urban sophistication meets outdoor adventure, and now, traditional power meets cutting-edge solar. Whether it's a LoDo high-rise or a suburban home in Capitol Hill, we're bringing solar solutions to the city that thrives on innovation.",
  paragraph3:
    "Nestled at the foot of Pikes Peak, Colorado Springs is known for its breathtaking beauty and commitment to sustainability. We're here to help homeowners harness the power of the sun, reducing energy costs while keeping this stunning city as green as the trails that wind through Garden of the Gods.",
  paragraph4:
    "Aurora's endless sunshine makes it a perfect match for solar power. Lunex Power is bringing top-tier solar solutions to homeowners, helping them save while embracing renewables. With over 300 sunny days a year, there's no better time to switch.",
  carousel: false,
};

export const southCarolinaData = {
  stateName: "South Carolina",
  stateTitle: "SOUTH CAROLINA",
  stateTitleSubHeader: "Solar Energy with Southern Charm",
  heroImage: southCarolinaHero,
  heroIcon: <SC size={200} color="#ffa500" />,
  heroIcon2: <SC size={150} color="#ffa500" />,
  image1: southCarolinaIMG,
  image1Alt: "Solar House on Golf Course",
  image2: charlestonSkyline,
  image2Alt: "Charleston Skyline",
  image3: columbiaSkyline,
  image3Alt: "Columbia Skyline",
  image4: greenvilleSkyline,
  image4Alt: "Greenville Skyline",
  cityTitle1: "CHARLESTON - Preserving the Past, Powering the Future",
  cityTitle2: "COLUMBIA - Solar for the Heart of the State",
  cityTitle3: "GREENVILLE - A Bright Future for the Upstate",
  paragraph1:
    "South Carolina's welcoming communities and sunny climate make it an ideal home for solar energy. Lunex Power is bringing top-tier solar solutions to homeowners who want to take advantage of the state's abundant sunshine while keeping their energy bills low.",
  paragraph2:
    "Charleston's historic charm is undeniable, but that doesn't mean the city isn't looking toward the future. Our solar solutions seamlessly blend with the city's timeless beauty, allowing homeowners to embrace clean energy without sacrificing the aesthetics that make Charleston unique.",
  paragraph3:
    "As the state capital, Columbia is all about progress, and solar energy is the next step forward. Lunex Power is making it easy for homeowners to embrace renewable energy, ensuring that the Palmetto State continues to shine—both literally and figuratively.",
  paragraph4:
    "Greenville's growth is unstoppable, and so is its move toward clean energy. Lunex Power is making it easy for homeowners to harness solar, keeping energy bills low and sustainability high. A booming city deserves a power source that keeps up.",
  carousel: false,
};

export const northCarolinaData = {
  stateName: "North Carolina",
  stateTitle: "NORTH CAROLINA",
  stateTitleSubHeader: "A Brighter Future in the Tar Heel State",
  heroImage: northCarolinaHero,
  heroIcon: <NC size={200} color="#ffa500" />,
  heroIcon2: <NC size={150} color="#ffa500" />,
  image1: northCarolinaIMG,
  image1Alt: "Solar House on Lake",
  image2: charlotteSkyline,
  image2Alt: "Charlotte Skyline",
  image3: raleighSkyline,
  image3Alt: "Raleigh Skyline",
  image4: greensboroSkyline,
  image4Alt: "Greensboro Skyline",
  cityTitle1: "CHARLOTTE - Energy in the Queen City",
  cityTitle2: "RALEIGH - Innovation in the Research Triangle",
  cityTitle3: "GREENSBORO - Solar for a City on the Rise",
  paragraph1:
    "North Carolina is booming, and so is its solar potential. With a thriving economy and forward-thinking homeowners, Lunex Power is making sure clean energy is part of the state's bright future.",
  paragraph2:
    "Charlotte is known as an energy hub, and we're here to make sure that hub includes solar. Whether you're in a high-rise in Uptown or a cozy neighborhood in NoDa, Lunex Power is helping homeowners make the switch to clean, cost-effective solar energy.",
  paragraph3:
    "With a reputation for tech and innovation, Raleigh is the perfect place for a solar revolution. We're making it easier than ever for homeowners to harness the power of the sun, proving that in a city that thrives on knowledge, smart energy choices are a no-brainer.",
  paragraph4:
    "Greensboro thrives on progress, and solar energy is the next step forward. Lunex Power is helping homeowners take charge of their energy future with affordable, efficient solar. In a city that's always looking ahead, clean energy is the way to go.",
  carousel: false,
};
