import styled from "styled-components";

export const CollabContainer = styled.div`
  height: auto;
  background-color: #fff;
  padding-bottom: 55px;

  @media screen and (max-width: 1050px) {
    padding-bottom: 100px;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 30px;
  }
`;

export const CollabHeaderWrapper = styled.div`
  height: 100px;
  width: 90%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;
  padding-top: 80px;
`;

export const CollabHeader = styled.h1`
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  color: #000;
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  white-space: pre-wrap;
  text-align: center;
  font-family: "Avenir_Book";
  padding-top: 40px;

  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
`;

export const CollabGrid = styled.div`
  display: grid;
`;

export const CollabWrapper = styled.div`
  width: 70%;
  height: auto;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  grid-auto-flow: column;
  /* place-items: center; */
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1050px) {
    margin-bottom: 55px;
    width: 90%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

export const SingleWrapper = styled.div`
  width: 50%;
  height: auto;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  grid-auto-flow: column;
  /* place-items: center; */
  align-items: center;
  margin-top: 55px;
  margin-bottom: 55px;

  @media screen and (max-width: 1050px) {
    margin-top: 10px;
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
    width: 50%;
  }
`;

export const CollabImg = styled.img`
  /* height: auto; */
  width: 100%;
  /* max-height: 100px; */
`;

export const FranklinImg = styled.img`
  /* height: auto; */
  width: 100%;
  padding-bottom: 5px;
  /* max-height: 100px; */
`;

export const BatteryTextWrapper = styled.div`
  width: 40%;
  margin-left: 53%;
  margin-top: -45vw;
  perspective: 65vw;

  /* @media screen and (max-width: 767px) {
        margin-top: -160px;
    } */
`;

export const BatteryText = styled.h1`
  position: relative;
  color: #fff;
  font-size: 5.4vw;
  line-height: 1.1;
  font-weight: 600;
  white-space: pre-wrap;
  text-align: center;
  font-family: "Avenir_Book";
  /* padding-top: 40px; */
  z-index: 1;
  /* rotate: ; */
  transform: rotateY(-45deg);
  /* transform: ; */

  /* @media screen and (max-width: 767px) {
        font-size: 16px;
    } */
`;

export const BatteryWrapper = styled.div`
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 17vw;

  @media screen and (max-width: 767px) {
    margin-bottom: 25vw;
  }
`;

export const BannerContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 10px;
  width: 100%;
  background-color: #fff;
`;

export const BannerWrapper = styled.div`
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 3px 3px #eee;

  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

export const BatteryImg = styled.img`
  width: 100%;
  display: block;
`;
