import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const SectionWrapper = styled.div`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  gap: 1rem;
  align-items: center;
  position: relative;
  z-index: 11;
  border-bottom: 4px solid #ffa500;
  ${({ $carousel }) =>
    !$carousel
      ? css`
          border-top: 4px solid #ffa500;
        `
      : null};

  @media (max-width: 31.25em) {
    //500px
    padding: 2rem;
  }
`;

const ContactTitle = styled.h3`
  font-size: 2rem;
  font-weight: 800;
  color: #ffa500;

  @media (max-width: 43.75em) {
    //700px
    font-size: 1.8rem;
  }

  @media (max-width: 31.25em) {
    //500px
    margin-bottom: 1rem;
  }
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 31.25em) {
    //500px
    flex-direction: column;
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
`;

const ContactText = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  color: #101522;
  text-align: center;

  @media (max-width: 43.75em) {
    //700px
    font-size: 1.2rem;
  }
`;

const ButtonSiteLink = styled(Link)`
  font-family: "Avenir_Book";
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1.4rem;
  background-color: #1ba8f1;
  background-color: #ffa500;
  color: black;
  transition: all 0.3s;
  border-radius: 50px;
  text-decoration: none;
  color: white;
  text-align: center;
  text-justify: center;

  &:hover {
    background-color: white;
    color: orange;
    box-shadow: inset 0 0 0 2px #ffa500;
    cursor: pointer;
  }

  @media (max-width: 43.75em) {
    //700px
    font-size: 1rem;
    padding: 0.6rem 1rem;
  }
`;

const ButtonLink = styled.a`
  font-family: "Avenir_Book";
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1.4rem;
  background-color: #1ba8f1;
  background-color: #ffa500;
  color: black;
  transition: all 0.3s;
  border-radius: 50px;
  text-decoration: none;
  color: white;
  text-align: center;
  text-justify: center;

  &:hover {
    background-color: white;
    color: orange;
    box-shadow: inset 0 0 0 2px #ffa500;
    cursor: pointer;
  }

  @media (max-width: 43.75em) {
    //700px
    font-size: 1rem;
    padding: 0.6rem 1rem;
  }
`;

const ContactFormButton = function ({ stateName, carousel }) {
  return (
    <SectionWrapper $carousel={carousel}>
      <ContactTitle>Contact us</ContactTitle>
      <LineWrapper>
        <ContactText>
          Contact Lunex {stateName ? stateName : "Power"}
        </ContactText>
        <ButtonSiteLink to="/contact-us">CONTACT US</ButtonSiteLink>
      </LineWrapper>
      <LineWrapper>
        <ContactText>Self service pricing on Lunex Direct app</ContactText>
        <ButtonLink href="https://app.lunexdirect.solar">GO TO APP</ButtonLink>
      </LineWrapper>
    </SectionWrapper>
  );
};

export default ContactFormButton;
