import Layout from "../../../components/Layout";
import ContactFormButton from "../contactFormButton";
import LocationState from "../locationState";
import StateHero from "../stateHero";
import { southCarolinaData } from "../data";
import MobileLocationState2 from "../mobileLocationState2";
import { useMobileContext } from "../../../components/MobileContext/MobileContextHook";

const SouthCarolina = function () {
  const { isMobile } = useMobileContext();

  return (
    <Layout>
      <StateHero {...southCarolinaData} />
      {isMobile ? (
        <MobileLocationState2 {...southCarolinaData} />
      ) : (
        <LocationState {...southCarolinaData} />
      )}
      <ContactFormButton {...southCarolinaData} />
    </Layout>
  );
};

export default SouthCarolina;
