import { Link } from "react-router-dom";
import styled from "styled-components";
import { FL, MA, RI, CT, CO, NC, SC } from "@state-icons/react";

const StateSectionWrapper = styled.section`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  border-top: 4px solid #ffa500;
`;

const StateCard = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 12px;
  padding: 2rem;
  height: 6rem;
  /* min-height: 10rem; */
  width: 14rem;
  /* min-width: 18rem; */
  gap: 1rem;
  transition: all 0.3s;
  background-color: #ffa500;

  &:hover,
  &:active {
    background-color: white;
    box-shadow: inset 0 0 0 3px #ffa500;

    h3,
    svg {
      color: #ffa500;
      fill: #ffa500;
    }
  }

  @media (max-width: 46.875em) {
    //750px
    height: 4rem;
    width: 8rem;
    padding: 1rem;
  }
`;

const CardTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  color: #ffa500;
  color: white;
  text-align: center;
  margin: auto 0;

  @media (max-width: 46.875em) {
    //750px
    font-size: 1rem;
  }
`;

const states = [
  { stateName: "Florida", stateLink: "florida" },
  { stateName: "Massachusetts", stateLink: "massachusetts" },
  { stateName: "Rhode Island", stateLink: "rhodeisland" },
  { stateName: "Connecticut", stateLink: "connecticut" },
  { stateName: "Colorado", stateLink: "colorado" },
  { stateName: "North Carolina", stateLink: "northcarolina" },
  { stateName: "South Carolina", stateLink: "southcarolina" },
];

const postalCodes = [
  <FL size={50} color="white" />,
  <MA size={50} color="white" />,
  <RI size={50} color="white" />,
  <CT size={50} color="white" />,
  <CO size={50} color="white" />,
  <NC size={50} color="white" />,
  <SC size={50} color="white" />,
];

const ServiceAreaStates = function () {
  return (
    <StateSectionWrapper>
      {states.map((state, i) => {
        return (
          <StateCard
            key={state.stateLink}
            to={`/service-areas/${state.stateLink}`}
          >
            <CardTitle>{state.stateName}</CardTitle>
            {postalCodes[i]}
          </StateCard>
        );
      })}
    </StateSectionWrapper>
  );
};

export default ServiceAreaStates;
