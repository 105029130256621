import styled from "styled-components";
// import faqHero from "../../images/FAQ/FAQHero.webp";
import faqHero2 from "../../images/FAQ/FAQHero2.webp";
import { newFadeIn, slideInFromLeft, slideInFromRight } from "../../animations";

export const FAQHeroWrapper = styled.div`
  width: 100%;
  min-height: calc(60vh + 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(${faqHero2});
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  z-index: 5;
  overflow: hidden;

  @media (max-width: 64em) {
    //1024px
    background-attachment: scroll;
  }

  @media (max-width: 46.875em) {
    //750px
    min-height: 45vh;
    background-position: center 75%;
  }

  @media (max-width: 28.125em) {
    //450px
    min-height: 35vh;
    background-position: center 70%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 6;
  }
`;

export const FAQTitleWrapper = styled.div`
  display: flex;
  z-index: 7;
  margin-top: 80px;
`;

export const FAQTitle = styled.h1`
  font-size: 12rem;
  font-weight: 600;
  color: white;
  color: #ffa500;
  text-shadow: 1px 1px 10px white;
  text-shadow: 1px 1px 10px #ffa500;

  &:first-of-type {
    animation: ${slideInFromLeft} 1s ease-in forwards;
  }
  &:nth-of-type(2) {
    animation: ${newFadeIn} 1s ease-in forwards;
  }
  &:last-of-type {
    animation: ${slideInFromRight} 1s ease-in forwards;
  }

  @media (max-width: 46.875em) {
    //750px
    font-size: 9rem;
  }

  @media (max-width: 28.125em) {
    //450px
    font-size: 8rem;
    text-shadow: 1px 1px 6px #ffa500;
  }
`;

const FAQHero = function () {
  return (
    <FAQHeroWrapper>
      <FAQTitleWrapper>
        <FAQTitle>F</FAQTitle>
        <FAQTitle>A</FAQTitle>
        <FAQTitle>Q</FAQTitle>
      </FAQTitleWrapper>
    </FAQHeroWrapper>
  );
};

export default FAQHero;
