export const equipment = [
  {
    question: "How does solar work?",
    answer:
      "Solar panels absorb sunlight and convert it into direct current (DC) electricity. An inverter then changes this DC power into alternating current (AC), which your home uses. Any excess energy can either be stored in a battery or sent back to the grid, depending on your setup.",
  },

  {
    question:
      "What happens if one of the panels breaks in 5 years? Who fixes it?",
    answer:
      "Lunex Power Inc. has a 30 year “bumper to bumper” warranty that covers all components. Lunex Power Inc. will be responsible for replacement of any broken parts on the solar system.",
  },

  {
    question: "What is the warranty?",
    answer:
      "Lunex Power Inc.'s warranty lasts for 30 years and covers all parts of the system! The warranty is null and void due to natural disasters, pest damage, hard strikes (ex. baseballs/golfballs), or removal of panels by any company or person(s) other than Lunex Power Inc.",
  },

  {
    question: "Does the system still work when the power goes out?",
    answer:
      "Unless you have a battery system such as the Tesla Powerwall 3 or the Enphase 5p, your system will not produce energy during a blackout. This is due to a national electric code for all systems to shut down during a blackout in order to prevent electrocution from downed power lines.",
  },

  {
    question: "What types of inverters do you use?",
    answer:
      "We use both string and microinverters. String inverters are attached to multiple panels and convert their solar energy from DC to AC power. Microinverters are attached to each individual panel and also convert solar energy from DC to AC power. The advantage of string inverter solutions is that they cost less since they require less individual inverters, while the disadvantage is that they can lead to a single point of failure if that inverter goes out. Microinverters cost more since they require you to purchase more inverters, but they ensure that your system will continue to function in the event that a panel goes out since there is no single point of failure.",
  },

  {
    question: "Which inverter manufacturers do you work with?",
    answer:
      "We work with both Tesla and Enphase. On the Tesla side we sell both the Tesla Inverter and the Tesla Powerwall 3 which is a solar battery with a built-in inverter. For Enphase we offer the Enphase IQ8+.",
  },
];

export const company = [
  {
    question: "What sets Lunex Power Inc. apart from other companies?",
    answer:
      "Lunex Power Inc. is a smaller, local company. This means that we will have more focus and flexibility with scheduling. You will be able to reach all our team directly without having to go through automatic answering machines. We keep all our work in-house, we do not subcontract any jobs (aside from roofing, tree cutting, or plumbing). We have a very competitive warranty and product offering to go along with our great solar team.",
  },

  {
    question: "What is the difference between Lunex Direct and Lunex Power?",
    answer:
      "Lunex Direct is a division of Lunex Power that is geared towards customers who prefer a direct model. They are able to design and purchase their own solutions through the Lunex Direct Solar Design Generator, saving both time and money. All contracted work will still be performed by Lunex Power. This means you still get the exact same quality of service, just at a lower rate since there will be no sales rep receiving a commission.",
  },

  {
    question: "How long have you been in business?",
    answer:
      "We started as a company in 2020 but the owner has been in the solar industry since 2009.",
  },

  {
    question: "Who does all the work, any subcontracting?",
    answer:
      "Lunex Power Inc. handles all of the work in-house. The customer is in contact with our employees starting with the salesmen all the way to the finished installation. We are even here to service your system once it is complete.",
  },
];

export const financial = [
  {
    question: "What is net metering?",
    answer:
      "Net metering allows you to send excess solar energy back to the grid in exchange for energy credits. When your system produces more electricity than you use, the extra power is fed into the utility grid, and your meter runs backward. At night or on cloudy days when your system isn’t producing enough, you can use those credits to offset your energy consumption, reducing your electric bill.",
  },

  {
    question: "Are there financing options available?",
    answer:
      "Yes, we work with trusted lenders to offer competitive financing options tailored to your budget.",
  },

  {
    question: "How does the tax credit work?",
    answer:
      "The federal tax credit lets you deduct 30% of your system’s cost from your taxes. The tax credit will be declining over the next few years so be sure to take advantage as soon as possible! We recommend meeting with a tax advisor for their expertise.",
  },
];

export const roof = [
  {
    question: "Is it going to ruin my roof?",
    answer:
      "No, installing solar will not harm your roof. Our install team is very precise and will make sure that all holes are sealed, and the roof is in the same condition it was to start.",
  },

  {
    question: "What if I need to get a new roof?",
    answer:
      "Lunex Power offers competitive roof quotes which include the cost of detach and resetting the panels when it's time to replace your roof and as long as you use our partners no additional cost will fall onto you.",
  },

  {
    question: "Will my roof leak? Why can't we put them on ground?",
    answer:
      "No, your roof will not leak. There will be holes drilled into your roof, but they will be sealed with flashing so that no water/moisture leaks in. Ground mounts are also an option for customers. They will incur added costs for extra equipment and trenching.",
  },
];
