import styled from "styled-components";
import FAQSection from "./faqSection";
import { company, equipment, financial, roof } from "./data";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 47% 47%;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 2rem 0;
  justify-content: center;

  @media (max-width: 75em) {
    //1200px
    grid-template-columns: 94%;
  }
`;

const FAQGrid = function () {
  return (
    <GridWrapper>
      <FAQSection title={"Equipment"} data={equipment} />
      <FAQSection title={"Company"} data={company} />
      <FAQSection title={"Financial"} data={financial} />
      <FAQSection title={"Roof"} data={roof} />
    </GridWrapper>
  );
};

export default FAQGrid;
