import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-color: #101522;
`;

export const FooterWrap = styled.div`
  position: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  font-family: "Avenir_Book";
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  margin-left: 80px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    margin-left: 20px;
  }
`;

export const FooterLogoImg = styled.img`
  height: 60px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  margin-top: -5px;
  margin-left: 24px;

  @media screen and (max-width: 820px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 480px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 450px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterLink = styled(LinkScroll)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #ffa500;
    transition: 0.3s ease-out;
  }
`;
export const FooterPageLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #ffa500;
    transition: 0.3s ease-out;
  }
`;

export const FooterLinkPhone = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #ffa500;
    transition: 0.3s ease-out;
  }
`;

export const FooterLinkHiring = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #ffa500;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const LicensesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  @media (max-width: 28.125em) {
    max-width: 70%;
  }
`;

export const Licenses = styled.small`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
  text-justify: center;

  &:first-of-type {
    font-size: 0.95rem;
    /* text-decoration: underline; */
    border-bottom: 1.5px solid #fff;
    margin-bottom: 0.6rem;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;

  &:hover {
    color: #ffa500;
  }
`;
