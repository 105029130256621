import Layout from "../../../components/Layout";
import ContactFormButton from "../contactFormButton";
import LocationState from "../locationState";
import StateHero from "../stateHero";
import { northCarolinaData } from "../data";
import MobileLocationState2 from "../mobileLocationState2";
import { useMobileContext } from "../../../components/MobileContext/MobileContextHook";

const NorthCarolina = function () {
  const { isMobile } = useMobileContext();

  return (
    <Layout>
      <StateHero {...northCarolinaData} />
      {isMobile ? (
        <MobileLocationState2 {...northCarolinaData} />
      ) : (
        <LocationState {...northCarolinaData} />
      )}
      <ContactFormButton {...northCarolinaData} />
    </Layout>
  );
};

export default NorthCarolina;
