import React, { useEffect, useState } from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SidebarLinkButton,
  SidebarExternalLink,
  SidebarLinkButtonExternal,
  SidebarRouterLink,
} from "./SidebarElements";
import { useLocation } from "react-router-dom";

const Sidebar = ({ isopen, toggle }) => {
  const [conf, setConf] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") {
      setConf(true);
    }
  }, [location.pathname]);

  return (
    <SidebarContainer $isopen={isopen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {/* {conf ? 
                <SideBtnWrap>
                    <SidebarLinkButton to='/' onClick={toggle}>
                        Home
                    </SidebarLinkButton>
                </SideBtnWrap>
            : */}
        <>
          <SidebarMenu>
            {conf ? (
              <SidebarRouterLink to="/" onClick={toggle}>
                Home
              </SidebarRouterLink>
            ) : (
              <SidebarLink
                to="about"
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                About
              </SidebarLink>
            )}
            {/* <SidebarLink
              to="services"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-60}
            >
              Services
            </SidebarLink> */}
            {/* <SidebarLink to='ourteam' onClick={toggle}>
                            Our Team
                        </SidebarLink> */}
            {/* <SidebarLink
              to="reviews"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-60}
            >
              Reviews
            </SidebarLink> */}
            <SidebarRouterLink to="/commercial" onClick={toggle}>
              Commercial
            </SidebarRouterLink>
            <SidebarRouterLink to="/service-areas" onClick={toggle}>
              Service Areas
            </SidebarRouterLink>
            <SidebarRouterLink to="/faq" onClick={toggle}>
              FAQ
            </SidebarRouterLink>
            {/* <SidebarLink to='requestquote' onClick={toggle} smooth={true} duration={500} spy={true} exact='true' offset={-60}>
                            Contact
                        </SidebarLink> */}
            {/* <SidebarExternalLink href='https://get.lunexpower.com/ebook/' target="_blank" onClick={toggle}>
                            E-Book
                        </SidebarExternalLink> */}
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarLinkButton to="/refer-email" onClick={toggle}>
              Refer a Friend
            </SidebarLinkButton>
          </SideBtnWrap>
          {conf ? (
            <SideBtnWrap>
              <SidebarLinkButton to="/contact-us" onClick={toggle}>
                Request a Quote
              </SidebarLinkButton>
            </SideBtnWrap>
          ) : (
            <SideBtnWrap>
              <SidebarRoute
                to="requestquote"
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
              >
                Request a Quote
              </SidebarRoute>
            </SideBtnWrap>
          )}
        </>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
