import Layout from "../../../components/Layout";
import Cities from "../cities";
import ContactFormButton from "../contactFormButton";
import LocationState from "../locationState";
import StateHero from "../stateHero";
import { floridaData } from "../data";
import MobileLocationState2 from "../mobileLocationState2";
import { useMobileContext } from "../../../components/MobileContext/MobileContextHook";

const Florida = function () {
  const { isMobile } = useMobileContext();

  return (
    <Layout>
      <StateHero {...floridaData} />
      {isMobile ? (
        <MobileLocationState2 {...floridaData} />
      ) : (
        <LocationState {...floridaData} />
      )}
      <Cities {...floridaData} />
      <ContactFormButton {...floridaData} />
    </Layout>
  );
};

export default Florida;
