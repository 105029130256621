import styled from "styled-components";
import Layout from "../../components/Layout";
import FAQHero from "./hero";
import FAQGrid from "./faqGrid";

const FAQPageWrapper = styled.section`
  box-sizing: border-box;
  font-family: "Avenir_Book", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  margin-top: -80px;
`;

const FAQ = function () {
  return (
    <Layout>
      <FAQPageWrapper>
        <FAQHero />
        <FAQGrid />
      </FAQPageWrapper>
    </Layout>
  );
};

export default FAQ;
