import React, { useEffect, useState } from "react";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavLogoImg,
  NavButton,
  MobileIconLinks,
  NavExternalLinks,
  NavButtonLink,
  NavAlt,
  NavLinksRouter,
  NavButtonRouterLink,
  NavButtonWrapper,
} from "./NavbarElements";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ toggle, img, alt, img2 }) => {
  const gaEventTracker = useAnalyticsEventTracker("Navbar");
  const [scrollnav, setScrollNav] = useState(0);
  const location = useLocation();
  const isReferEmail = location.pathname.startsWith("/refer-email");
  const isContactUs = location.pathname.startsWith("/contact-us");
  const isLeadForm = location.pathname.startsWith("/lead-form");
  const isReferMailer = location.pathname.startsWith("/refer-mailer");

  const changeNav = () => {
    if (window.scrollY >= 50) {
      setScrollNav(1);
    } else {
      setScrollNav(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [conf, setConf] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/") {
      setConf(true);
    }
  }, [location.pathname]);

  return (
    <>
      {/* {conf ? (
        <NavAlt>
          <NavbarContainer>
            <Link to="/">
              <NavLogoImg
                to="/"
                onClick={toggleHome}
                src={img}
                alt={alt}
                $conf={conf}
              />
            </Link>
            <MobileIconLinks onClick={toggle}>
              <FaBars />
            </MobileIconLinks>
            <NavMenu> */}
      {/* <NavItem>
                            <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('About')}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='services' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Services')}>Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='reviews' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Reviews')}>Reviews</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='requestquote' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Contact Us')}>Contact</NavLinks>
                        </NavItem> */}
      {/* </NavMenu>
            <NavBtn>
              <NavButton as={Link} to="/">
                Home
              </NavButton>
            </NavBtn>
          </NavbarContainer>
        </NavAlt>
      ) : ( */}
      <Nav
        $scrollnav={scrollnav}
        $isReferEmail={isReferEmail}
        $isContactUs={isContactUs}
        $isLeadForm={isLeadForm}
        $isReferMailer={isReferMailer}
      >
        <NavbarContainer>
          {conf ? (
            <Link to="/">
              <NavLogoImg to="/" onClick={toggleHome} src={img} alt={alt} />
            </Link>
          ) : (
            <NavLogoImg to="/" onClick={toggleHome} src={img} alt={alt} />
          )}
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {conf ? (
              <NavItem>
                <NavLinksRouter
                  to="/"
                >
                  Home
                </NavLinksRouter>
              </NavItem>
            ) : (
              <NavItem>
                <NavLinks
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}
                  onClick={() => gaEventTracker("About")}
                >
                  About
                </NavLinks>
              </NavItem>
            )}
            {/* <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-60}
                onClick={() => gaEventTracker("Services")}
              >
                Services
              </NavLinks>
            </NavItem> */}
            {/* <NavItem>
                                <NavLinks to='reviews' smooth={true} duration={500} spy={true} exact='true' offset={-60} onClick={()=>gaEventTracker('Reviews')}>Reviews</NavLinks>
                            </NavItem> */}
            <NavItem>
              <NavLinksRouter
                to="/commercial"
                onClick={() => gaEventTracker("Commercial")}
              >
                Commercial
              </NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksRouter
                to="/service-areas"
                onClick={() => gaEventTracker("Service Areas")}
              >
                Service Areas
              </NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksRouter to="/faq" onClick={() => gaEventTracker("FAQ")}>
                FAQ
              </NavLinksRouter>
            </NavItem>

            {/* <NavItem>
                                <NavExternalLinks href='https://get.lunexpower.com/ebook/' target="_blank" onClick={()=>gaEventTracker('E-Book')}>E-Book</NavExternalLinks>
                            </NavItem> */}
          </NavMenu>
          <NavButtonWrapper>
            <NavBtn>
              <NavButtonRouterLink
                to="/refer-email"
                onClick={() => gaEventTracker("Refer Email")}
              >
                Refer a Friend
              </NavButtonRouterLink>
            </NavBtn>
            {conf ? (
              <NavBtn>
                <NavButtonRouterLink
                  to="/contact-us"
                  onClick={() => gaEventTracker("Request a Quote")}
                >
                  Request a Quote
                </NavButtonRouterLink>
              </NavBtn>
            ) : (
              <NavBtn>
                <NavBtnLink
                  to="requestquote"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-60}
                  onClick={() => gaEventTracker("Request a Quote")}
                >
                  Request a Quote
                </NavBtnLink>
              </NavBtn>
            )}
          </NavButtonWrapper>
        </NavbarContainer>
      </Nav>
      {/* )} */}
    </>
  );
};

export default Navbar;
