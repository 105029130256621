import styled from "styled-components";
import { slideDown, slideInFromRight } from "../../animations.js";
import lunexPowerLogo from "../../images/LunexPowerLogoPlain.png";
import heroImage from "../../images/ServiceAreas/ServiceAreasHero.webp";

const LocationsHeroWrapper = styled.section`
  font-family: "Avenir_Book";
  box-sizing: border-box;
  background-image: url(${heroImage});
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 80px);
  min-height: 100vh;
  margin-top: -80px;
  position: relative;
  z-index: 6;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 7;
  }

  @media (max-width: 64em) {
    //1024px
    background-attachment: scroll;
  }

  @media (max-width: 37.5em) {
    //600px
    min-height: 60vh;
  }

  @media (max-aspect-ratio: 0.8) {
    justify-content: center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
  margin-top: calc(2rem + 80px);
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 8;

  @media (max-aspect-ratio: 0.8) {
    margin-bottom: 40%;
  }
`;

const TitleLogo = styled.img`
  aspect-ratio: 3.6496;
  width: 34rem;
  margin-right: 1rem;
  animation: ${slideDown} 2s ease forwards;

  @media (max-width: 37.5em) {
    //600px
    width: 90vw;
  }
`;

const LocationsHeroTitle = styled.h1`
  display: inline-block;
  font-size: 4rem;
  font-weight: 600;
  font-family: "Avenir_Book";
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  opacity: 0;
  color: #ffa500;
  transform: translateX(100px);
  animation: ${slideInFromRight} 2s ease forwards;

  @media (max-width: 37.5em) {
    //600px
    font-size: 3rem;
  }

  @media (max-width: 28.125em) {
    //450px
    font-size: 2.4rem;
  }
`;

const LocationsHero = function () {
  return (
    <LocationsHeroWrapper>
      <TitleWrapper>
        <TitleLogo src={lunexPowerLogo} alt="Lunex Power Logo" />
        <LocationsHeroTitle>Service Areas</LocationsHeroTitle>
      </TitleWrapper>
    </LocationsHeroWrapper>
  );
};

export default LocationsHero;
