import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { useState } from "react";
import { newFadeIn } from "../../animations";

const MobileStateWrapper = styled.section`
  box-sizing: border-box;
  font-family: "Avenir_Book";
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;

export const StateTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  margin: 0 2rem 2rem 2rem;
`;

const StateTitle = styled.h2`
  font-size: 3.2rem;
  color: #ffa500;
  font-weight: 600;

  @media (max-width: 37.5em) {
    //600px
    font-size: 2rem;
  }
`;

export const StateTitleSubHeader = styled.h3`
  font-size: 1.4rem;
  color: black;
  font-weight: 600;
  text-align: center;

  @media (max-width: 37.5em) {
    //600px
    font-size: 1rem;
  }
`;

export const TitleParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  position: sticky;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;

  animation: ${newFadeIn} 1.5s ease forwards;
`;

export const CityTitle = styled.h3`
  font-size: 1.2rem;
  color: #ffa500;
  font-weight: 600;
  z-index: 20;
`;

export const Paragraph = styled.p`
  font-size: 1.1rem;
  color: white;
  z-index: 20;
  margin-bottom: 1rem;

  @media (max-width: 37.5em) {
    //600px
    font-size: 1rem;
  }
`;

const SwiperStyled = styled.div`
  position: relative;
  aspect-ratio: 605.75/ 433.01;
  border-radius: 10px;
  overflow: hidden;
  max-width: 28rem;
  max-width: 90%;
  height: 80vh;
  /* max-height: 80%; */
  animation: ${newFadeIn} 1.5s ease forwards;
  margin: 0 2rem 1.6rem 2rem;

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 10 !important;
    opacity: 700%;

    @media (max-width: 38.75em) {
      //620px
      top: 40%;
    }

    @media (max-width: 25.625em) {
      //410px
      top: 25%;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px;
    font-weight: 600;
  }

  .swiper-pagination-bullet {
    z-index: 21;
  }
`;

const StateImage = styled.img`
  aspect-ratio: 605.75/ 433.01;
  /* max-width: 28rem; */
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const MobileLocationState2 = ({
  stateTitle,
  stateTitleSubHeader,
  image1,
  image2,
  image3,
  image4,
  image1Alt,
  image2Alt,
  image3Alt,
  image4Alt,
  cityTitle1,
  cityTitle2,
  cityTitle3,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
}) => {
  const slidesData = [image1, image2, image3, image4];

  const slideAlts = [image1Alt, image2Alt, image3Alt, image4Alt];

  const [activeImage, setActiveImage] = useState(slidesData[0]);

  return (
    <MobileStateWrapper>
      <StateTitleWrapper>
        <StateTitle>{stateTitle}</StateTitle>
        <StateTitleSubHeader>{stateTitleSubHeader}</StateTitleSubHeader>
      </StateTitleWrapper>
      <SwiperStyled>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          navigation={true}
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, disableOnInteraction: true }}
          className="mySwiper"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            marginBottom: "2rem",
          }}
          onSlideChange={(swiper) => {
            const currentIndex = swiper.realIndex; // realIndex gives you the current slide
            setActiveImage(slidesData[currentIndex]);
          }}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide
              key={slide + (index + 1)}
              style={{ borderRadius: "10px" }}
            >
              <StateImage
                src={slide}
                alt={slideAlts[index]}
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              {activeImage === image1 ? (
                <TitleParagraphWrapper key="img1">
                  <Paragraph>{paragraph1}</Paragraph>
                </TitleParagraphWrapper>
              ) : activeImage === image2 ? (
                <TitleParagraphWrapper key="img2">
                  <CityTitle>{cityTitle1}</CityTitle>
                  <Paragraph>{paragraph2}</Paragraph>
                </TitleParagraphWrapper>
              ) : activeImage === image3 ? (
                <TitleParagraphWrapper key="img3">
                  <CityTitle>{cityTitle2}</CityTitle>
                  <Paragraph>{paragraph3}</Paragraph>
                </TitleParagraphWrapper>
              ) : activeImage === image4 ? (
                <TitleParagraphWrapper key="img4">
                  <CityTitle>{cityTitle3}</CityTitle>
                  <Paragraph>{paragraph4}</Paragraph>
                </TitleParagraphWrapper>
              ) : null}
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperStyled>
    </MobileStateWrapper>
  );
};

export default MobileLocationState2;
