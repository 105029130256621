import React from "react";
// import ReactDOM from 'react-dom';
import App from "./App";
import FontStyles from "./fontStyles";
import { createRoot } from "react-dom/client";
import MobileContextProvider from "./components/MobileContext/MobileContextProvider";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    <MobileContextProvider>
      <FontStyles />
      <App />
    </MobileContextProvider>
  </>
);
